<template>
  <section>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus
              :title="
                $t('components.pods.sunpods.details.steps.alarmLogs.headline')
              "
              :hide-plus="true"
            />
          </div>
        </div>
      </div>
      <template v-if="true">
        <div>
          <STable :sId-id="stId" :headers="getTableHeaders">
            <div v-if="reqBusy">
              <loading
                :active.sync="reqBusy"
                :is-full-page="false"
                :z-index="10"
                :opacity="0.25"
              />
            </div>
            <template v-else>
              <template v-for="(item, itemIndex) in alarmLogs">
                <template v-if="getTableMode === 'full'">
                  <STableRow :key="itemIndex" text-fallback-always>
                    <STableRowItem>
                      {{ item.device }}
                    </STableRowItem>
                    <STableRowItem>
                      {{ item.type }}
                    </STableRowItem>
                    <STableRowItem>
                      {{ item.description }}
                    </STableRowItem>
                    <STableRowItem>
                      {{ getTimeFromUnix(item.started) }}
                    </STableRowItem>
                    <STableRowItem>
                      {{
                        milisecToDHMSConverter(
                          Number(item.cleared - item.started) * 1000,
                          item.cleared
                        ) || '--'
                      }}
                    </STableRowItem>
                  </STableRow>
                </template>
                <template v-if="getTableMode === 'responsive'">
                  <STableRow :key="itemIndex" text-fallback-always>
                    <STableRowItem>
                      <div
                        class="col-span-1 focus:text-gray-400"
                        @click="toggle(itemIndex)"
                      >
                        <i
                          class="fas fa-minus-circle"
                          style="color:#d90a20;"
                          v-if="opened.includes(itemIndex)"
                        ></i>
                        <i class="fas fa-plus-circle" v-else></i>
                      </div>
                    </STableRowItem>
                    <STableRowItem>
                      {{ item.device }}
                    </STableRowItem>
                    <STableRowItem>
                      {{ getTimeFromUnix(item.started) }}
                    </STableRowItem>
                    <STableRowItem>
                      {{
                        milisecToDHMSConverter(
                          Number(item.cleared - item.started) * 1000,
                          item.cleared
                        ) || '--'
                      }}
                    </STableRowItem>
                  </STableRow>
                  <STableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                    <td colspan="10">
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.pods.sunpods.details.steps.alarmLogs.table.columns.type'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          {{ item.type }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.pods.sunpods.details.steps.alarmLogs.table.columns.description'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          {{ item.description || '--' }}
                        </div>
                      </div>
                    </td>
                  </STableRow>
                </template>
              </template>
            </template>
          </STable>
          <div v-if="!reqBusy && alarmLogs.length == 0">
            <transition>
              <div
                class="flex flex-col justify-center items-center p-5 my-8 w-full text-center"
              >
                <img
                  src="@/assets/placeholder/no-data-undraw.svg"
                  class="w-44 h-44 animate-pulse"
                  alt="No Data"
                />
                <div class="mt-5 font-semibold text-gray-500 text-14px">
                  Oops! No Data Found
                  <i class="text-base far fa-meh-blank" />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </template>

      <div v-else class="py-5 font-bold text-center text-gray-600">
        {{ $t('components.acl.doNotHavePermission') }}
      </div>
    </content-section>
  </section>
</template>

<script>
import { STable, STableRow, STableRowItem } from '@/components/s-table'
import { SunpodsConfig } from '@/config/SunpodsConfig'
import { getUTCAwareTime } from '@/utils'
export default {
  name: 'ViewSunpodAlarmLogs',

  props: {
    pageTitle: {
      type: String,
      default: 'Alarm Logs',
    },
    stId: {
      type: String,
      default: 'sunpod-alarm-logs',
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    STable,
    STableRow,
    STableRowItem,
  },

  data() {
    return {
      reqBusy: false,
      alarmLogs: [],
      windowWidth: window.innerWidth,
      tableHeaders: [
        {
          text: this.$t(
            'components.pods.sunpods.details.steps.alarmLogs.table.columns.device'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.sunpods.details.steps.alarmLogs.table.columns.type'
          ),
          width: '14%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.sunpods.details.steps.alarmLogs.table.columns.description'
          ),
          width: '15%',
          sort: 'date',
        },
        {
          text: this.$t(
            'components.pods.sunpods.details.steps.alarmLogs.table.columns.alarmStartedAt'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.sunpods.details.steps.alarmLogs.table.columns.alarmClearedAfter'
          ),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: 'Device',
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.sunpods.details.steps.alarmLogs.table.columns.alarmStartedAt'
          ),
          width: '35%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.sunpods.details.steps.alarmLogs.table.columns.alarmClearedAfter'
          ),
          width: '35%',
          sort: null,
        },
      ],
      opened: [],
    }
  },

  async created() {
    this.reqBusy = true
    await this.$http
      .get(SunpodsConfig.api.detail(this.$route.params.id))
      .then((res) => {
        this.alarmLogs = Array.isArray(res.data.alarms) ? res.data.alarms : []
      })
      .finally(() => (this.reqBusy = false))
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    milisecToDHMSConverter(ms, time) {
      const years = Math.floor(ms / (12 * 30 * 24 * 60 * 60 * 1000))
      const yearsms = ms % (12 * 30 * 24 * 60 * 60 * 1000)
      const months = Math.floor(yearsms / (30 * 24 * 60 * 60 * 1000))
      const monthsms = ms % (30 * 24 * 60 * 60 * 1000)
      const days = Math.floor(monthsms / (24 * 60 * 60 * 1000))
      const daysms = ms % (24 * 60 * 60 * 1000)
      const hours = Math.floor(daysms / (60 * 60 * 1000))
      const hoursms = ms % (60 * 60 * 1000)
      const minutes = Math.floor(hoursms / (60 * 1000))
      const minutesms = ms % (60 * 1000)
      const seconds = Math.floor(minutesms / 1000)

      let textTime = ''

      if (years > 0) {
        textTime += `${years}y `
      }
      if (months > 0) {
        textTime += `${months}mo `
      }
      if (days > 0) {
        textTime += `${days}d `
      }
      if (hours > 0) {
        textTime += `${hours}h `
      }
      if (minutes > 0) {
        textTime += `${minutes}h `
      }
      if (seconds > 0) {
        textTime += `${seconds}s `
      }

      if (textTime.length === 0) {
        return this.getTimeFromUnix(time)
      } else {
        return textTime
      }
    },
    getTimeFromUnix(unixTimeString) {
      let toFormattedDate

      if (unixTimeString) {
        let timeString
        if (unixTimeString.toString().length === 10) {
          timeString = new Date(unixTimeString * 1000)
        } else {
          timeString = new Date(
            Number(unixTimeString.toString().slice(0, 10)) * 1000
          )
        }
        toFormattedDate = getUTCAwareTime(timeString.toISOString())
      }

      return toFormattedDate
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },

    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
